import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SubscriptionGuide.module.scss';  // 스타일 파일을 SubscriptionGuide에 맞게 수정
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/SubscriptionGuide/SubscriptionGuide1/page1.jpg";

const SubscriptionGuide1 = () => {
	const menuContents = [
		{ title: "청약자격안내", url: "/SubscriptionGuide/intro" }, // 새로운 항목 추가
		{ title: "인터넷청약", url: "/SubscriptionGuide/internet" }, // 새로운 항목 추가
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로
	}, [pathname]);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

			<Helmet>
				<title>청약안내 - 평택 브레인시티 한신더휴</title>
				<meta name="description" content="평택 브레인시티 한신더휴의 청약 안내를 확인하세요. 청약 자격 및 절차, 인터넷 청약 방법을 상세히 안내합니다." />
				<meta name="keywords" content="평택브레인시티한신더휴, 브레인시티청약, 청약자격안내, 인터넷청약, 평택브레인시티한신더휴 청약" />
				<link rel="canonical" href="https://www.theporest.co.kr/SubscriptionGuide/intro" />
			</Helmet>
			
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="청약안내" />

			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
           			<div>평택 브레인시티의 핵심입지</div>
           		 	<div>한신더휴의 체크포인트를 확인해보세요.</div>
         		</div>
		

			<img src={page1} className={styles.locationImg} alt="premium-image-1" />


			<Footer />
		</div>
	);
};

export default SubscriptionGuide1;
