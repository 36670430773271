import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SubscriptionGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import page2 from "../../assets/SubscriptionGuide/SubscriptionGuide2/page2.jpg";

const SubscriptionGuide2 = () => {
	const menuContents = [
		{ title: "청약자격안내", url: "/SubscriptionGuide/intro" },
		{ title: "인터넷청약", url: "/SubscriptionGuide/internet" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로
	}, [pathname]);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

			<Helmet>
				<title>인터넷청약방법 - 평택 브레인시티 한신더휴</title>
				<meta name="description" content="평택 브레인시티 한신더휴의 청약자격안내 페이지. 청약 자격 조건과 필요한 서류를 확인하세요." />
				<meta name="keywords" content="평택브레인시티한신더휴, 브레인시티청약자격, 청약자격, 청약자격조건" />
				<link rel="canonical" href="https://www.theporest.co.kr/SubscriptionGuide/internet" />
			</Helmet>
			
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="인터넷 청약방법" />

			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
           			<div>평택 브레인시티 한신더휴</div>
           		 	<div>인터넷 청약방법을 확인해보세요.</div>
         		</div>

			<img src={page2} className={styles.locationImg} alt="SubscriptionGuide2-image-2" />


			<Footer />
		</div>
	);
};

export default SubscriptionGuide2;
