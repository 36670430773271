import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './ComplexGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/ComplexGuide/ComplexGuide1/page1.jpg"
import page2 from "../../assets/ComplexGuide/ComplexGuide1/page2.jpg"

const ComplexGuide1 = () => {
	const menuContents = [
		{ title: "단지 배치도", url: "/ComplexGuide/intro" },
		{ title: "호수 배치도", url: "/ComplexGuide/detailintro" },
		{ title: "커뮤니티", url: "/ComplexGuide/community" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

			<Helmet>
				<title>단지배치도 - 평택 브레인시티 한신더휴</title>
				<meta name="description" content="평택 브레인시티 한신더휴의 단지배치도를 통해 단지의 전체 구조와 배치를 확인하세요. 
				효율적인 공간 활용과 자연 친화적인 설계를 통해 쾌적한 단지 환경을 제공합니다." />
				<meta name="keywords" content="평택브레인시티한신더휴, 브레인시티한신더휴, 평택브레인시티한신더휴 모델하우스, 브레인시티한신더휴 모델하우스,평택브레인시티푸르지오" />
				<link rel="canonical" href="https://www.theporest.co.kr/ComplexGuide/intro" />
			</Helmet>

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="단지안내" />

			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
				<div>평택 브레인시티 한신더휴</div>
				<div>자연 속에 휴식, 놀이, 문화가 함께 하는 명품단지</div>
			</div>

			<img src={page1} className={styles.image} alt="ComplexGuide-image-1" />
			<img src={page2} className={styles.image2} alt="ComplexGuide-image-2" />
			


			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ ※ 상기 CG 및 이미지는 소비자의 이해를 돕기 위해 제작된 것으로 실제와 다르며 인·허가 과정에서 다소 변경될 수 있으니 견본주택을 방문하시어 직접 확인하시기 바랍니다.
				</div>
				<div className={styles.notice}>
					※ 본 홈페이지의 제작과정상 오탈자 등 오류가 있을 수 있으므로 계약 시 반드시 확인하시기 바랍니다.
				</div>
		
			</div>

			<Footer />
		</div>
	)
}

export default ComplexGuide1;
