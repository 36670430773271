import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './FloorPlan.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/FloorPlan/FloorPlan5/page1.jpg";
import page2 from "../../assets/FloorPlan/FloorPlan5/page2.jpg";

const FloorPlan5 = () => {
	const menuContents = [
		{ title: "59A", url: "/FloorPlan/59A" },
		{ title: "59B", url: "/FloorPlan/59B" },
		{ title: "84A", url: "/FloorPlan/84A" },
		{ title: "84B", url: "/FloorPlan/84B" },
		{ title: "84C", url: "/FloorPlan/114A" },
		{ title: "84D", url: "/FloorPlan/114B" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="세대안내" />

			<MenuBar contents={menuContents} />

			<Helmet>
				<title>평면안내 84C - 평택 브레인시티 한신더휴</title>
				<meta name="description" content="평택 브레인시티 한신더휴의 유니트는 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다. 
				각 유닛은 세련된 인테리어와 최신 편의 시설을 갖추고 있어, 최적의 주거 경험을 제공합니다. 
				다양한 평면 옵션을 통해 개인의 라이프스타일과 필요에 맞춰 선택할 수 있습니다." />
				<meta name="keywords" content="평택브레인시티한신더휴, 브레인시티한신더휴, 평택브레인시티한신더휴 모델하우스, 브레인시티한신더휴 모델하우스,평택브레인시티푸르지오" />
				<link rel="canonical" href="https://www.theporest.co.kr/FloorPlan/114A" />
			</Helmet>

			<img src={page1} className={styles.image} alt="FloorPlan-image-1" />
			<img src={page2} className={styles.image2} alt="FloorPlan2-image-2" />



			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 CG 및 이미지는 소비자의 이해를 돕기 위해 제작된 것으로 실제와 다르며 인·허가 과정에서 다소 변경될 수 있으니 견본주택을 방문하시어 직접 확인하시기 바랍니다.
				</div>
				<div className={styles.notice}>
					※ 본 홈페이지의 제작과정상 오탈자 등 오류가 있을 수 있으므로 계약 시 반드시 확인하시기 바랍니다.
				</div>
		
			</div>

			<Footer />
		</div>
	)
}

export default FloorPlan5;
